<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      class="dialog-box"
      v-model="dialog"
      max-width="1800px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="dialog = false"
      content-class="overflow-hidden"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <div style="text-align: center"><h1>Well Integrity Report</h1></div>

          <v-toolbar flat elevation="1" style="margin-top: 3px">
            <div>
              <v-btn id="menu-activator" plain color="blue darken-1">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
              <v-menu
                activator="#menu-activator"
                :close-on-content-click="false"
              >
                <v-list>
                  <v-list-item
                    v-for="menu in menuList"
                    :key="menu.id"
                    :value="menu.id"
                    dense
                  >
                    <template v-slot:default>
                      <v-list-item-action dense>
                        <v-checkbox v-model="menu.checked"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-title>{{ menu.label }}</v-list-item-title>
                    </template>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <h2>{{ selected_well.wellname }}</h2>
            <div style="margin-left: 50px">
              <h2
                :class="
                  'font-weight-light text-center  grow ' +
                  selected_well.integrity_status_color
                "
              >
                {{ selected_well.integrity_status_name }}
              </h2>
            </div>
            <v-spacer></v-spacer>
            <!-- <a
          href="#"
          @click="downloadsvg"
          class="link-download btn btn-primary btn-sm"
          >Download</a
        > -->
            <v-btn
              class="ma-1"
              color="blue darken-1"
              @click.stop="export_pdf"
              :disabled="desbale_pdf"
              >PDF</v-btn
            >
            <v-btn class="ma-1" color="blue darken-1" @click.stop="close"
              >Close</v-btn
            >
          </v-toolbar>
          <div
            id="page"
            style="
              width: 100%;
              height: 100vh;
              overflow-y: auto;
              margin-top: 6px;
            "
          >
            <v-row>
              <v-col cols="5" sm="6" md="5">
                 <component_svg  
                  :components="components_list" 
                  :components_list="components_list_details"
                  :tubulars="tubulars_list"
                  :formations="wellsformations"
                  :failures="failures"
                  :key="ksvg1"
                  :svg_id="'svg_r'">   
                 </component_svg>
              </v-col>

              <v-col cols="7" sm="6" md="7">
                <div v-if="selected.includes(0)" style="margin-right: 10px">
                  <div v-if="well.fingers_list && evaluation">
                    <h2>Evaluation</h2>
                    <br />
                    <div id="vEvaluation">
                      <h3>
                        <b>Date:</b> &nbsp;{{ evaluation.eval_date }}
                        <br />
                        <b>Evaluated by:</b>
                        &nbsp;{{ this.evaluation.eval_name }}<br />
                        <b>Evaluation:</b>
                        <p>{{ evaluation.comment }}</p>
                        <br />
                      </h3>
                    </div>
                  </div>
                  <div v-else>NO Evaluation</div>
                </div>
                <div v-if="selected.includes(11)">
                  <br />
                  <div v-if="failures" style="margin-right: 10px">
                    <h2>Failures</h2>
                    <br />
                    <div id="vFailures">
                      <listitems
                        :list="failures"
                        :headers="failures_headers"
                        :toolbar="false"
                        :hdf="true"
                        :ipg="-1"
                        :elevation="10"
                        :key="kfail"
                      >
                      </listitems>
                    </div>
                  </div>
                  <div v-else>NO Failures</div>
                </div>
                <br />
                <div v-if="selected.includes(2)">
                  <h2>Corrosion test</h2>
                  <br />
                  <div id="vCorrosion">
                    <listitems
                      :title="'Corrosion'"
                      :list="corrosion_barriers"
                      :toolbar="false"
                      :headers="corrosionheaders"
                      :key="kcorrosion"
                      :showstd="true"
                      :add="false"
                      :del="false"
                      :hdf="true"
                      :ipg="-1"
                      :elevation="10"
                    >
                    </listitems>
                  </div>
                </div>
                <div v-if="selected.includes(3)">
                  <h2>Cements test</h2>
                  <br />
                  <div id="vCement">
                    <listitems
                      :title="'Cement'"
                      :list="cement_barriers"
                      :toolbar="false"
                      :headers="cementheaders"
                      :key="kcement"
                      :showstd="true"
                      :add="false"
                      :del="false"
                      :hdf="true"
                      :ipg="-1"
                      :elevation="10"
                    >
                    </listitems>
                  </div>
                </div>
                <div v-if="selected.includes(4)">
                  <h2>APM</h2>
                  <chartform
                    :well_id="parseInt(selected_well.id)"
                    :tubulars_list="tubulars"
                    :chart_id="chart_id"
                    :key="kchart"
                    @chart-ready="onChartReady"
                  ></chartform>
                </div>
                <div v-if="selected.includes(10)">
                  <div v-if="well.fingers_list">
                    <h2>Finger Print</h2>
                    <v-container>
                      <v-card>
                        <v-card-title v-if="formation_match">
                          <span>
                            {{ conclusion }}
                          </span>
                        </v-card-title>
                        <v-card-text>
                          <div id="vFinger">
                            <canvas
                              id="myChart22"
                              width="400"
                              height="400"
                            ></canvas>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-container>
                  </div>
                  <div v-else>NO Finger print</div>
                </div>
                <div v-if="selected.includes(5)">
                  <div v-if="listBO.length > 0">
                    <h2>BO test</h2>
                    <boform :list="listBO"></boform>
                  </div>
                  <div v-else>No BO TEST</div>
                </div>
                <div v-if="selected.includes(6)" style="margin-right: 8px">
                  <div v-if="test_dhsv.length > 0">
                    <h2>DHSV test</h2>
                    <v-row dense style="margin-top: 4px">
                      <v-col cols="4">
                        <v-text-field
                          :value="test_dhsv[0].hyd_oil"
                          label="Hydraulic oil return"
                          dense
                          persistent-placeholder
                          outlined
                          readonly
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          :value="test_dhsv[0].o_pressure"
                          label="Closing time"
                          dense
                          persistent-placeholder
                          outlined
                          readonly
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          :value="test_dhsv[0].o_time"
                          label="Opening time"
                          dense
                          persistent-placeholder
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="4">
                        <v-text-field
                          :value="test_dhsv[0].i_pressure"
                          label="Initial pressure"
                          dense
                          persistent-placeholder
                          outlined
                          readonly
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          :value="test_dhsv[0].f_pressure"
                          label="Final pressure"
                          dense
                          persistent-placeholder
                          outlined
                          readonly
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-text-field
                          :value="test_dhsv[0].time"
                          label="Time"
                          dense
                          persistent-placeholder
                          outlined
                          readonly
                        />
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>No DHSV TEST</div>
                </div>

                <div v-if="selected.includes(7)">
                  <div v-if="valvesXT.length > 0" style="margin-right: 8px">
                    <h2>Xtree test</h2>
                    <div
                      style="
                        border-style: groove;
                        border-width: 2px;
                        margin-top: 4px;
                      "
                      id="vXtree"
                    >
                      <valveform :list="valvesXT" :test="'Xtree '"></valveform>
                    </div>
                  </div>
                  <div v-else>No Xtree TEST</div>
                </div>
                <div v-if="selected.includes(8)" style="margin-right: 8px">
                  <div v-if="valvesWH.length > 0">
                    <h2>Wellhead test</h2>
                    <div
                      style="
                        border-style: groove;
                        border-width: 2px;
                        margin-top: 4px;
                      "
                      id="vWhead"
                    >
                      <valveform
                        :list="valvesWH"
                        :test="'WellHead '"
                      ></valveform>
                    </div>
                  </div>
                  <div v-else>No WellHead TEST</div>
                </div>
                <div v-if="selected.includes(9)">
                  <div v-if="downs.length > 0">
                    <h2>Down test</h2>
                    <downform :list="downs"></downform>
                  </div>
                  <div v-else>No Downhole TEST</div>
                </div>
                <div v-if="selected.includes(1)">
                  <h2>Map</h2>
                  <GmapMap
                    ref="mapRef"
                    :center="center"
                    :zoom="zoom_map"
                    :key="kmap"
                    map-type-id="satellite"
                  >
                    <GmapMarker :position="center"> </GmapMarker>
                  </GmapMap>
                </div>
                <givenform
                  :dialog="givendialog"
                  :wellstypes="wellstypes"
                  :failure="failure"
                  :given="given"
                  :given_action="given_action"
                  @close_given="close_given"
                  :key="kga"
                ></givenform>

                <waiter :showWait="showWait"> </waiter>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import jsPDF from "jspdf";
import Chart from "chart.js";
import html2canvas from "html2canvas";
import WELL from "../graphql/Well/WELL.gql";
import CMP_BARRIERS from "../graphql/Tests/CMP_BARRIERS.gql";
import DATA from "../graphql/Well/DATA.gql";
import CREATE_TEST_FAILURE from "../graphql/Tests/CREATE_TEST_FAILURE.gql";
import DELETE_TEST_FAILURE from "../graphql/Tests/DELETE_TEST_FAILURE.gql";
import UPDATE_TEST_FAILURE from "../graphql/Tests/UPDATE_TEST_FAILURE.gql";
import GIVEN_ACTION from "../graphql/Tests/GIVEN_ACTION.gql";

import ALL_FP from "../graphql/FingerPrint/FPS_ALL_DETAIL.gql";
export default {
  components: {
    component_svg: () => import("../components/svg_well.vue"),
    listitems: () => import("../components/ListItems.vue"),
    waiter: () => import("../components/Widgets/Waiter.vue"),
    boform: () => import("../components/TestForms/BO.vue"),
    valveform: () => import("../components/TestForms/Valves.vue"),
    downform: () => import("../components/TestForms/Downhole.vue"),
    givenform: () => import("../components/GivenAction.vue"),

    chartform: () => import("../components/Chart_Form.vue"),
  },
  props: {
    well_rapport: Object,
    dialog: Boolean,
  },
  data() {
    return {
      desbale_pdf: false,
      svgElement: null,
      chartElement: null,
      evaluation: {},
      kchart: 10000000,
      tubulars: [],
      chart_id: "MyChart33",
      well: {},
      given: {},
      givendialog: false,
      given_action: [],
      apm: [],
      listBO: [],
      test_dhsv: [],
      valvesXT: [],
      valvesWH: [],
      wellstypes: [],
      downs: [],
      menuList: [
        { id: 0, label: "Evaluation", checked: true },
        { id: 1, label: "Map", checked: false },
        { id: 11, label: "Failures", checked: true },
        { id: 2, label: "Corrosion", checked: false },
        { id: 3, label: "Cement", checked: false },
        { id: 4, label: "APM", checked: true },
        { id: 5, label: "BO", checked: false },
        { id: 6, label: "DHSV", checked: false },
        { id: 7, label: "Xtree", checked: false },
        { id: 8, label: "Wellhead", checked: false },
        { id: 9, label: "Downhole", checked: false },
        { id: 10, label: "Finger print", checked: true },
      ],
      isMap: false,
      isCorrosion: false,
      isCement: false,
      isAPM: false,
      isBO: false,
      isDHSV: false,
      center: { lat: 34.4, lng: 2.78 },
      zoom_map: 6,
      selected_well: {},
      showWait: false,
      zoom: 1.2,
      dialogform: false,
      ksvg1: 2000,
      barriers: [],
      failures_cmp_list: [],
      well_svg: {},
      components_list: [],
      components_other: [],
      wellsformations: [],
      components_list_details: [],
      corrosion_barriers: [],
      cement_barriers: [],
      tubulars_list: [],
      failures_list: [],
      risks_list: [],
      wmf_list: [],
      failures: [],
      failures_report: [],
      failure: {},
      formation_match: "",
      kfail: 40000,
      kga: 6000,
      kcorrosion: 100,
      kcement: 5000,
      conclusion: "",
      crossed: false,
      cementheaders: [
        {
          text: "String",
          value: "sizes",
          selected: true,
        },
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
        },
        {
          text: "Evaluation",
          value: "level",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
      corrosionheaders: [
        {
          text: "String",
          value: "sizes",
          selected: true,
        },
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
        },
        {
          text: "Evaluation",
          value: "evaluation",
          slot: "progress",
          color: "color",
          selected: true,
        },
        {
          text: "Level",
          value: "level",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
      headers: [
        {
          text: "Waiver",
          slot: "col_btn1",
          selected: true,
          icon: "pen-cil",
          color: "indigo",
          width: "10",
          valeur: 0,
          sortable: false,
        },
        {
          text: "Item",
          value: "issue",
          selected: true,
        },
        {
          text: "Element",
          value: "item",
          selected: true,
        },
        {
          text: "Failure rate(%)",
          value: "failure_taux",
          selected: true,
        },
        {
          text: "Risk Ranking",
          value: "risk",
          selected: true,
        },
        {
          text: "Measure Action",
          value: "action",
          selected: true,
        },
        {
          text: "Barrier",
          value: "barrier",
          selected: false,
        },
        {
          text: "Repair Date",
          value: "repair_date",
          selected: true,
        },
        {
          text: "Code Action",
          value: "code_given_action",
          selected: true,
        },
        {
          text: "Given action",
          value: "given_action",
          selected: true,
        },
      ],
      failures_headers: [
        {
          text: "Element",
          value: "component",
          selected: true,
        },
        {
          text: "Description",
          value: "discription",
          selected: true,
        },

        {
          text: "Failure rate(%)",
          value: "failure_taux",
          slot: "progress",
          align: "center",
          color: "color",
          width: 140,
          selected: true,
        },
        {
          text: "Risk Ranking",
          value: "risk",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
      downs_headers: [
        { text: "Component", value: "label" },
        { text: "Init. Pressure", value: "i_pressure" },
        { text: "Fin. Pressure", value: "f_pressure" },
        { text: "Dropped. Pressure", value: "drop_pressure" },
      ],
      valves_headers: [
        { text: "Component", value: "label" },
        { text: "Operation", value: "operat" },
        { text: "Opening", value: "o_turns" },
        { text: "Closing", value: "c_turns" },
        { text: "Init. Pressure", value: "i_pressure" },
        { text: "Fin. Pressure", value: "f_pressure" },
      ],
      bo_headers: [
        { text: "Component", value: "label" },
        { text: "Init. Pressure", value: "i_pressure" },
        { text: "Bleed of to", value: "f_pressure" },
        { text: "Continous Flow", value: "c_flow" },
        { text: "1HR PBU", value: "f_pressure_12hrs" },
        { text: "24HR PBU", value: "f_pressure_24hrs" },
      ],
      kmap: 1200,
    };
  },
 
  watch: {},
  async mounted() {

    if (this.well_rapport) {
      this.well = Object.assign({}, this.well_rapport);
      this.selected_well = Object.assign({}, this.well);
    
      if (this.well.wellsevaluations) {
        if (this.well.wellsevaluations.length > 0)
          this.evaluation = this.well.wellsevaluations[0];
      }
      //    this.selected_well = this.well;
      this.center = {
        lat: this.selected_well.latitude,
        lng: this.selected_well.longitude,
      };
      if (this.dialog) this.getdata(parseInt(this.selected_well.id));
      if (this.well.fingers_list)
        if (this.well.fingers_list.length > 0) {
          this.chart_show();
        }
      let r = await this.$requette(DATA);
      if (r.ok) {
        this.tubulars = r.data.tubulars;
      }
      
    }
       
  },
  computed: {
    Qfailure_create() {
      return CREATE_TEST_FAILURE;
    },

    Qfailure_delete() {
      return DELETE_TEST_FAILURE;
    },
    selected() {
      return this.menuList.filter((e) => e.checked).map((e) => e.id);
    },
  },
  methods: {
    select_menu() {
      if (this.selected.includes(10)) {
        this.chart_show();
      }
    },
    close() {
      this.$emit("close");
    },
    close_given(item) {
      if (item) {
        this.getdata(parseInt(this.selected_well.id));
      }
      this.givendialog = false;
    },
    FailureOpen(item) {
      this.failure = item;
      this.getGivenAction();
      this.givendialog = true;
      this.kga++;
    },
    FailureSelect(item) {
      this.failure = item;
    },
    async getdata(id) {
      this.showWait = true;
      let r = await this.$requette(
        WELL,
        {
          TypeScope: id,
          CatScope: this.$store.state.fields,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.allwellsstates.length > 0)
          this.well = r.data.allwellsstates[0];
        this.components_list = r.data.allwellsstates[0].wellscomponents;
        this.components_list_details =
          r.data.allwellsstates[0].wellscomponents_details;
        this.tubulars_list = r.data.allwellsstates[0].wellstubulars;
        this.wellsformations = r.data.allwellsstates[0].wellsformations; 
      }
      let r3 = await this.$requette(
        CMP_BARRIERS,
        {
          WellID: id,
        },
        "no-cache"
      );
      if (r3.ok) {
        //
        this.apm = r3.data.test_qapm;
        this.listBO = r3.data.test_qbo;
        this.test_dhsv = r3.data.test_qdhsv;
        this.valvesXT = r3.data.test_qvalvesXT;
        this.valvesWH = r3.data.test_qvalvesWH;
        this.downs = r3.data.test_qdownhead;
        this.components_other = r3.data.components_list;
        this.failures_list = r3.data.components_type_list;
        this.risks_list = r3.data.risks_list;
        this.wmf_list = r3.data.wmf_list;
        this.failures = r3.data.test_failures_single;
        //  this.failures_multiple = r3.data.test_failures_multiple;
        //  this.failures_report = r3.data.test_failures_all;
        this.failures_cmp_list = r3.data.failures_cmp_list;
        this.corrosion_barriers = r3.data.test_corrosions_list;
        this.cement_barriers = r3.data.test_cements_list;
        this.wellstypes = r3.data.wellstypes;
        this.given_action = r3.data.given_action;
        this.kfail++;
        this.kcorrosion++;
        this.kcement++;
      }
      this.kchart++;
        this.ksvg1++;
    //  this.get_svg();
      this.showWait = false;
     
    },
    get_svg() {
      this.well_svg = {};
      //treecap
      let treecap = this.components_list.findIndex((x) => x.code == "4");
      if (treecap > 0)
        this.well_svg.gauge = {
          id: this.components_list[treecap].id,
          code: "4",
          label: "TreeCap",
        };

      //addxtree_top

      let xtree_top = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter(
          (x) => x.group_id == 1 && x.orientation == "T" && x.code != "4"
        );
      if (this.components_list.length > 0 || xtree_top.legth > 0)
        this.well_svg.Manifold = { id: 2000, code: "200", label: "Manifold" };
      let xtree_left = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter((x) => x.group_id == 1 && x.orientation == "L");
      let xtree_right = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter((x) => x.group_id == 1 && x.orientation == "R");
      let xtree = this.components_list
        .sort((a, b) => b.code - a.code)
        .filter((x) => x.group_id == 1 && x.orientation == null);

      if (xtree_top.length > 0) this.well_svg.xtree_top = xtree_top;
      if (xtree_left.length > 0)
        this.well_svg.xtree_left = { components: xtree_left };
      if (xtree_right.length > 0)
        this.well_svg.xtree_right = { components: xtree_right };
      if (xtree.length > 0) this.well_svg.xtree = xtree;
      let k = this.tubulars_list.findIndex((x) => x.tubular_id == 1);
      if (k >= 0)
        this.well_svg.tubulars = {
          depth: this.tubulars_list[k].depth,
          components: this.components_list.filter((x) => x.group_id == 13),
        };
      this.well_svg.Annulus = [];

      let annulus = this.components_list.filter((x) => x.group_id == 2);
      let casings = this.tubulars_list
        .filter((x) => x.tubular_id != 1 && x.depthfrom == 0)
        .sort((a, b) => a.csg_order - b.csg_order);
      let csg_order = 0;
      if (casings.length > 0) {
        casings.forEach((x) => {
          this.well_svg.Annulus.push({
            csg_order: csg_order + 1,
            depth: x.depth,
            topcement: x.topcement,
            components: annulus.filter((i) => i.csg_order == csg_order + 1),
          });
          csg_order++;
        });
      }
      //CSG
      let csgs = this.components_list.filter((x) => x.group_id == 9);
      if (csgs.length > 0) this.well_svg.csgs = csgs;
      //tubing 30
      let t30 = this.components_list.findIndex((x) => x.code == "30");
      if (t30 >= 0)
        this.well_svg.TUBING30 = {
          id: this.components_list[t30].id,
          code: "30",
          label: "Tubing",
        };
      //tubing 31
      let t31 = this.components_list.findIndex((x) => x.code == "31");
      if (t31 >= 0)
        this.well_svg.TUBING31 = {
          id: this.components_list[t31].id,
          code: "31",
          label: "Tubing",
        };
      //DHSV
      let t32 = this.components_list.findIndex((x) => x.code == "52");
      if (t32 >= 0)
        this.well_svg.TUBING32 = {
          id: this.components_list[t32].id,
          code: "52",
          label: "DHSV",
        };
      //corrosion
      this.well_svg.corrosion_barriers = this.corrosion_barriers;
      //cement
      this.well_svg.cement_barriers = this.cement_barriers;
      //formations
      this.well_svg.formations = this.wellsformations;
      this.dialogform = true;
    //  this.ksvg1++;
    },
    async add_failure(item) {
      if (item) {
        this.showWait = true;
        var x = ";" + String(item.cmp_code) + ";";
        var chemin = "";
        let items = [];

        let brs = this.wmf_list.filter((item) => {
          return item.chemin.match(x);
        });
        let fls = this.failures
          .filter((f) => f.cmp_code != "0")
          .filter((item) => {
            return item.barrier.match(x);
          });
        if (fls.length > 0) {
          fls.forEach((element) => {
            if (items.findIndex((i) => i == element.barrier) < 0)
              items.push(element.barrier);
          });
        }
        if (brs.length > 0) {
          //  let index = 10000;
          brs.forEach((element) => {
            // let i = element.chemin.indexOf(x);
            // if (i >= 0 && i < index) {
            //   chemin = element.chemin;
            //   index = i;
            // }
            items.push(element.chemin);
          });
        }
        if (chemin != "") {
          if (items.findIndex((i) => i == chemin) < 0) items.push(chemin);
        }

        let v = this.calculate_barrier_taux(item, items);

        let r;
        let k = this.failures.findIndex((i) => i.cmp_code == item.cmp_code);
        if (k >= 0) {
          v.id = this.failures[k].id;
          let wf = { testfailures: v };
          r = await this.$maj(UPDATE_TEST_FAILURE, wf);
        } else {
          let wf = { testfailures: v };
          r = await this.$maj(CREATE_TEST_FAILURE, wf);
        }
        if (r.ok) {
          let r3 = await this.$requette(CMP_BARRIERS, {
            WellID: parseInt(this.well.id),
          });
          if (r3.ok) {
            this.failures_list = r3.data.components_type_list;
            this.risks_list = r3.data.risks_list;
            this.failures = r3.data.test_failures_single;
            this.failures_report = r3.data.test_failures_all;
            this.kfail++;
          }
        }
        this.showWait = false;
       
      }
  
    },
    calculate_barrier_taux(item, items) {
      let barrier = "";
      let t_barrier = 100;
      let t_failure = 100;
      items.forEach((i) => {
        let brs = i.split(";");
        let ord_value = 1;
        let ord_f_total = 0;
        let ord_b_total = 0;
        let ord = 0;
        let val = 0;
        let external = -1;
        let taux_barrier = 0;
        let taux_failure = 0;
        brs.forEach((b) => {
          if (b != "") {
            let fls = this.failures.filter((x) => x.cmp_code == b);
            let k = fls.findIndex((f) => f.cmp_code == b);
            if (ord_value == 1) ord = 3;
            else if (ord_value == 2) ord = 2;
            else if (ord_value == 3) ord = 1;
            else ord = 0.5;
            ord_value++;
            ord_b_total = ord_b_total + ord;
            if (external < 0) {
              ord_f_total = ord_f_total + ord;
              if (b == item.cmp_code) {
                external = item.external;
                taux_failure = taux_failure + ord * item.failure_coef;
              } else taux_failure = taux_failure + ord * 100;
            }
            if (b == item.cmp_code) val = item.failure_coef;
            else if (k >= 0) {
              val = fls[k].failure_coef;
            } else val = 100;
            taux_barrier = taux_barrier + ord * val;
          }
        });
        if (ord_b_total > 0)
          taux_barrier = Math.round(taux_barrier / ord_b_total); //ceil
        if (ord_f_total > 0)
          taux_failure = Math.round(taux_failure / ord_f_total);
        if (taux_barrier <= t_barrier) {
          barrier = i;
          t_barrier = taux_barrier;
          t_failure = taux_failure;
        }
      });
      return {
        cmp_id: item.cmp_id,
        cmp_code: item.cmp_code,
        failure_id: item.failure_id,
        formation_id: item.formation_id,
        barrier_taux: t_barrier,
        failure_taux: t_failure,
        failure_coef: item.failure_coef,
        barrier: barrier,
        well_id: this.selected_well.id,
      };
    },
    sortBy() {
      return function (a, b) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      };
    },
    async chart_show() {
      let formations = [];
      let r = await this.$maj(ALL_FP, {
        TypeScope: 1,
        CatScope: this.$store.state.fields,
      });
      if (r.ok) {
        this.refrences_list = r.data.fingers_prints;
        this.refrences_list
          .filter(
            (elm) =>
              elm.statut_id == 6 &&
              elm.field_id == this.well.field_id &&
              elm.fluid_id == this.well.fingers_list[0].fluid_id &&
              elm.test_date <= this.well.fingers_list[0].test_date
          )
          .forEach((elm) => {
            let i = formations.findIndex(
              (elm2) => elm2.formation_id == elm.formation_id
            );
            if (i == -1) formations.push(elm);
            else {
              if (formations[i].test_date < elm.test_date) formations[i] = elm;
            }
          });
        this.well.fingers_list[0].details.forEach((element) => {
          formations.forEach((formation) => {
            let i = formation.details.findIndex(
              (elm) => elm.element_id == element.element_id
            );
            if (i >= 0) {
              formation.details[i].diff = Math.abs(
                formation.details[i].value - element.value
              );
            }
          });
        });

        formations.forEach((formation) => {
          formation.diff = formation.details.reduce(
            (a, b) => a + (b["diff"] || 0),
            0
          );
        });
        formations.sort(this.sortBy("diff"));

        if (formations.length > 0) {
          this.formation_match = formations[0].formation_label;
          let j = this.well.wellsformations.findIndex(
            (elm) =>
              elm.formation_id == formations[0].formation_id &&
              elm.depthfrom > 0
          );

          this.crossed = j >= 0;
          if (this.well.fingers_list.length > 0 && this.formation_match) {
            this.conclusion =
              "The " +
              this.well.fingers_list[0].fluid_label +
              " sample collected from " +
              this.well.fingers_list[0].annulus +
              " is matching with " +
              this.well.fingers_list[0].fluid_label +
              " reference " +
              this.formation_match +
              " formation" +
              (this.crossed ? "" : " which is not crossed");
          }
        }
      }

      const data = {
        labels: [],
        datasets: [],
      };
      data.labels = this.well.fingers_list[0].details.map(
        (elm) => elm.element_label
      );
      data.datasets.push({
        label: "Data Test",
        data: this.well.fingers_list[0].details.map((elm) => elm.value),
        fill: true,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgb(255, 99, 132)",
        pointBackgroundColor: "rgb(255, 99, 132)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)",
      });

      formations.forEach((element) => {
        let n1 = Math.floor(Math.random() * 255);
        let n2 = Math.floor(Math.random() * 255);
        let n3 = Math.floor(Math.random() * 255);
        data.datasets.push({
          label: element.formation_label,
          data: element.details.map((elm) => elm.value),
          fill: true,
          backgroundColor: "rgba(" + n1 + ", " + n2 + ", " + n3 + ", 0.2)",
          borderColor: "rgb(" + n1 + ", " + n2 + ", " + n3 + ")",
          pointBackgroundColor: "rgb(" + n1 + ", " + n2 + ", " + n3 + ")",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(" + n1 + ", " + n2 + ", " + n3 + ")",
        });
      });

      const config = {
        type: "radar",
        data: data,
        options: {
          elements: {
            line: {
              borderWidth: 3,
            },
          },
        },
        scales: {
          r: {
            angleLines: {
              display: false,
            },
            suggestedMin: 20,
            suggestedMax: 50,
          },
        },
        zoom: {
          enabled: true,
          mode: "y",
        },
      };
      setTimeout(() => {
        var ctx = document.getElementById("myChart22");
        new Chart(ctx, config);
      }, 50);
    },
    onSvgReady(svg) {
      this.svgElement = svg;
    },
    onChartReady(chart_element) {
      this.chartElement = chart_element;
    },
    async export_pdf() {
      this.desbale_pdf = true;
      const doc = new jsPDF("portrait", "pt", "a4");
      let pageH = 900;
      //Header
      var img = new Image();
      img.src = require("@/assets/logo.jpeg");
      this.setHeader(doc, img);

      doc.setFontSize(16);
      doc.text("Well Integrity Report", 210, 120);
      // well infos
      let xtop = 130;
      doc.setFontSize(12);
      doc.text(this.selected_well.wellname, 40, xtop + 10);
      doc.setTextColor(this.selected_well.integrity_status_color);
      doc.text(this.selected_well.integrity_status_name, 40, xtop + 25);
      doc.setTextColor("black");
      xtop = 160;

      //Well Graphic vertical center on page
      const canvas1 = await html2canvas(this.svgElement, {
        useCORS: true,
        width: this.svgElement.clientWidth - 5,
        height: this.svgElement.clientHeight,
        backgroundColor: "white",
        removeContainer: true,
      });

      const svgImage = await canvas1.toDataURL("image/jpeg", 1.0);
      doc.addImage(svgImage, "jpeg", 20, 180, 200, 1600);

      //Evaluation
      if (this.selected.includes(0)) {
        let div = document.querySelector("#vEvaluation");
        const canvasEv = await html2canvas(div, {
          useCORS: true,
          removeContainer: true,
        });
        const Eval = await canvasEv.toDataURL("image/jpeg", 0.8);
        doc.text("Evaluation:", 210, xtop);
        doc.addImage(
          Eval,
          "jpeg",
          210,
          xtop + 10,
          360,
          (2 * div.clientHeight) / 4
        );
        xtop = xtop + div.clientHeight - 20;
      }

      //failures
      if (this.selected.includes(11)) {
        doc.text("Failures:", 210, xtop);
        this.settable(
          doc,
          210,
          xtop + 10,
          this.failures_headers,
          this.failures
        );
        xtop = xtop + (this.failures.length + 1) * 28;
      }
      //APM
      if (this.selected.includes(4)) {
        doc.text("APM:", 210, xtop);
        const canvas2 = await html2canvas(this.chartElement, {
          useCORS: true,
          width: this.chartElement.clientWidth,
          height: this.chartElement.clientHeight,
          backgroundColor: "#141627",
          removeContainer: true,
        });
        const chartApm = await canvas2.toDataURL("image/jpeg", 0.8);
        doc.addImage(chartApm, "jpeg", 210, xtop + 10, 370, 220);
        xtop = xtop + 250; //220 is fixed height for chart
      }
      //Figer
      if (this.selected.includes(10)) {
        let divFinegr = document.querySelector("#vFinger");
        if (xtop + 320 > pageH) {
          //949:Pageheight
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleft = doc.internal.getNumberOfPages() > 1 ? 150 : 210;
        doc.text("Finger print:", xleft, xtop);

        const canvas3 = await html2canvas(divFinegr, {});
        const finger = await canvas3.toDataURL("image/jpeg", 0.8);
        doc.addImage(finger, "jpeg", xleft, xtop + 10, 320, 320);
        xtop = xtop + 350;
      }

      //corrosion ------------------------------------------------------------------------------
      if (this.selected.includes(2)) {
        if (xtop + (this.corrosion_barriers.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleftcr = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("Corrosion:", xleftcr, xtop);
        this.settable(
          doc,
          xleftcr,
          xtop + 10,
          this.corrosionheaders,
          this.corrosion_barriers
        );
        xtop = xtop + (this.corrosion_barriers.length + 1) * 28;
      }
      //--------
      //cement ------------------------------------------------------------------------------
      if (this.selected.includes(3)) {
        if (xtop + (this.cement_barriers.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleftcr = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("Cement:", xleftcr, xtop);
        this.settable(
          doc,
          xleftcr,
          xtop + 10,
          this.cementheaders,
          this.cement_barriers
        );
        xtop = xtop + (this.cement_barriers.length + 1) * 28;
      }
      //--------
      ///Xtree
      if (this.selected.includes(7)) {
        if (xtop + (this.valvesXT.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleft = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("Xtree:", xleft, xtop);
        this.settable(
          doc,
          xleft,
          xtop + 10,
          this.valves_headers,
          this.valvesXT
        );

        xtop = xtop + (this.valvesXT.length + 1) * 28;
      }
      //--------
      ///wellhead
      if (this.selected.includes(8)) {
        if (xtop + (this.valvesWH.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleft = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("Wellhead:", xleft, xtop);
        this.settable(
          doc,
          xleft,
          xtop + 10,
          this.valves_headers,
          this.valvesWH
        );
        xtop = xtop + (this.valvesWH.length + 1) * 28;
      }
      //BoTest

      if (this.selected.includes(5)) {
        if (xtop + (this.listBO.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleft = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("BO Test:", xleft, xtop);
        this.settable(doc, xleft, xtop + 10, this.bo_headers, this.listBO);
        xtop = xtop + (this.listBO.length + 1) * 28;
      }
      //downs
      if (this.selected.includes(9)) {
        if (xtop + (this.downs.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleft = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("Down hole:", xleft, xtop);
        this.settable(doc, xleft, xtop + 10, this.downs_headers, this.downs);
        xtop = xtop + (this.downs.length + 1) * 28;
      }
      //DHSV
      if (this.selected.includes(6)) {
        if (xtop + (this.listBO.length + 1) * 28 > pageH) {
          doc.addPage("portrait", "pt", "a4");
          this.setHeader(doc, img);
          xtop = 120;
        }
        let xleft = doc.internal.getNumberOfPages() > 1 ? 100 : 210;

        doc.text("DHSV Test:", xleft, xtop);
        doc.setFontSize(12);
        doc.text(
          this.test_dhsv[0].hyd_oil ? this.test_dhsv[0].hyd_oil : "120",
          xleft,
          xtop + 35
        );
        doc.text(
          this.test_dhsv[0].o_pressure ? this.test_dhsv[0].o_pressure : "130",
          xleft + 120,
          xtop + 35
        );
        doc.text(
          this.test_dhsv[0].o_time ? this.test_dhsv[0].o_time : "140",
          xleft + 220,
          xtop + 35
        );

        doc.text(
          this.test_dhsv[0].i_pressure ? this.test_dhsv[0].i_pressure : "200",
          xleft,
          xtop + 65
        );
        doc.text(
          this.test_dhsv[0].f_pressure ? this.test_dhsv[0].f_pressure : "210",
          xleft + 120,
          xtop + 65
        );
        doc.text(
          this.test_dhsv[0].time ? this.test_dhsv[0].time : "220",
          xleft + 220,
          xtop + 65
        );
        doc.setFont(undefined, "bold");
        doc.setFontSize(10);
        doc.text("Hyd return:", xleft, xtop + 20);
        doc.text("Closing time:", xleft + 120, xtop + 20);
        doc.text("Opening time:", xleft + 220, xtop + 20);

        doc.text("Initial Pressure:", xleft, xtop + 50);
        doc.text("Final Pressure:", xleft + 120, xtop + 50);
        doc.text("Time:", xleft + 220, xtop + 50);
        xtop = xtop + 60;
      }
      this.desbale_pdf = false;
      // await doc.save("testodf");
      // let blob = doc.output("blob");
      //  window.open(URL.createObjectURL(blob));
      //   printWindow.print();
      doc.output("pdfobjectnewwindow");
    },
    async setHeader(doc, img) {
      doc.addImage(img, "jpeg", 10, 0, 80, 90);
      doc.line(10, 90, 580, 90);
    },
    async settable(doc, startX, startY, headers, data) {
      let cols = [];
      let hcols = [];
      headers
        .filter((x) => (x.selected ? x.selected : true))
        .forEach((h) => cols.push({ content: h.text }));
      hcols.push(cols);
      let rows = [];
      data.forEach((x) => {
        let r = [];
        let i = 0;
        headers
          .filter((x) => (x.selected ? x.selected : true))
          .forEach((h) => {
            r[i] = x[h.value];
            i++;
          });
        rows.push(r);
      });
      doc.autoTable({
        head: hcols,
        body: rows,
        headStyle: { valign: "middle", halign: "center" },
        alternateRowStyles: { fillColor: [231, 215, 252] },
        styles: {
          overflow: "linebreak",
          cellWidth: "wrap",
          fontSize: 10,
          cellPadding: 4,
          overflowColumns: "linebreak",
        },

        margin: { left: startX },
        startY: startY,
        theme: "grid",
      });
    },
    async getGivenAction() {
      let r = await this.$requette(
        GIVEN_ACTION,
        {
          failures: this.failure.item,
        },
        "no-cache"
      );
      if (r.ok) {
        if (r.data.getGivenAction[0]) this.given = r.data.getGivenAction[0];
        else
          this.given = {
            id: null,
            failures: this.failure.item,
            t_1: -1,
            t_2: -1,
            t_3: -1,
            t_4: -1,
            t_5: -1,
            t_6: -1,
            t_7: -1,
            t_8: -1,
          };
      }
    },
  },
};
</script>
<style>
.vue-map-container,
.vue-map-container .vue-map {
  height: 400px;
  width: 100%;
  min-height: 100%;
  max-height: none;
}
.vue-map-container .gm-ui-hover-effect {
  display: none !important;
}
.dialog-box {
  z-index: 99999;
}
</style>
